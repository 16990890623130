import React, { Component } from 'react';
import { Container, Row, Col, Spinner , Collapse , Card , CardBody} from 'reactstrap';
import Api from '../../service/Api';
import { HashLink as Link } from 'react-router-hash-link';
import LogoSendero from './../../pago-rapido/Assets/Images/LogoSendero.png';
import Alert from '../../assets/images/alert.png';
import './DetailPagos.scss';
class DetailPago extends Component {
    constructor(props) {
        super(props);

        this.state = {
            buyOrder: 0,
            detalle: [],
            show: true,
            collapses: []
        }
        this.getDetalle = this.getDetalle.bind(this);
    }
    componentDidMount() {
        this.setState({ show: true });
        this.getDetalle();
    }
    async getDetalle() {
        //console.info(this.props)
        const { match } = this.props;
        const { params } = match;
        let data = {};
        let auxCollapse = [];

        data.buy_order = params.buy_order;
        data.token = params.token;
        let response = await Api.getDetailtsPaymentGeneric(data);
        console.info(response)

        if(Array.isArray(response)) {
            response[0].detail_cuota.map((item,index) => {
                let aux = this.getViewTrasanction(response,index);
                auxCollapse.push({isopen: false , status: aux.status  , class: aux.class  , view: aux.view})
            })
        }

        this.setState({ detalle: response, buyOrder: params.buy_order, show: false , collapses:  auxCollapse});
         
    }
    FormatDate(fecha, type) {
        let date = new Date(fecha)
        if (type === 'date') {
            return (date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear());
        } else {
            let min = date.getMinutes();
            console.info(min < 10)
            return (date.getHours() + ":" + (min < 10 ? '0' + min : min));
        }
    }
    TraduccionTypeCode(nomenclatura) {
        let val = '';
        switch (nomenclatura) {
            case 'VD':
                val = 'Venta Débito';
                break;
            case 'VP':
                val = 'Venta Prepago';
                break;
            case 'VN':
                val = 'Venta Normal (Sin Cuotas)';
                break;
            case 'VC':
                val = 'Venta Con Cuotas (con interés Banco)';
                break;
            case 'SI':
                val = '3 Cuotas sin interés';
                break;
            case 'S2':
                val = '2 Cuotas sin interés';
                break;
            case 'NC':
                val = 'N Cuotas sin interés';
                break;
            default:
                val = 'Sin Descripción';
                break;
        }

        return val;
    }

    getViewTrasanction = (response ,index) =>{
        let val = {class: '' , status : '' , view: false};
        let aux = '';
        let res = null;

        try {
            aux = response[0]?.detail_mall[index]?.status;    
            //console.log(aux);

            if(aux === undefined || aux == 'undefined' ) {
                if(response[0]?.status === 3) {
                    aux = 'AUTHORIZED';
                } else {
                    aux = 'FAILED';
                }
            }
            //console.log(aux);
        }catch (e) {
            aux = '';

            if(response[0]?.status === 3) {
                aux = 'AUTHORIZED';
            } else {
                aux = 'FAILED';
            }

        }
        
        switch(aux){
            case 'AUTHORIZED':
                val.status = 'Pago aceptado';
                val.class= 'bgsendero bgsendero-success';
                break;
            
            case 'FAILED':
                val.status = 'Pago rechazado';
                val.class= 'bgsendero bgsendero-error'
                val.view = true 
                break;

            case '':
                
                val.status = 'Pago rechazado';
                val.class= 'bgsendero bgsendero-error'
                val.view = true    
                
                break;

            default:
                val.status = 'Pago rechazado';
                val.class= 'bgsendero bgsendero-error'
                val.view = true
        }

        return val;
    }

    setToogleCollapse(index,view) {

        if(view === false) {
            return false;
        }

        let aux = this.state.collapses;

        aux[index].isopen = !aux[index].isopen;

        this.setState({collapses: aux});

    }

    render() {
        const { detalle, show } = this.state;

        return (
            <>
                {show ? (
                    <div className="text-center spinner">
                        <Spinner style={{ width: '5rem', height: '5rem' }} />
                    </div>
                ) : (
                    // detalle && detalle.length > 0 && detalle[0].status === 3 ? (
                        // detalle && detalle.length > 0 ? (
                        detalle && detalle.length > 0 && detalle[0]?.status == 3 ? (
                        <Container>

                            <Row>
                                <Col>
                                    <div className="text-center mb-3">
                                        <h2 className="text-title-detalle-pago">Comprobante de Pago</h2>
                                        <div className="text-subtitle-detalle-pago">Estimado (a) <span>{detalle[0].detail.nombre}</span>, Junto con saludarle adjuntamos su comprobante electrónico que registra el pago de su cuenta en Mi Sendero</div>
                                    </div>
                                    {/* <div>
                                        <p className="mis-datos"><strong>Mis Datos</strong></p>
                                    </div> */}
                                    <div className="back-detalle">
                                        <table width="100%">
                                            <tbody>
                                                <tr>
                                                    <th>Nombre Cliente : </th>
                                                    <td>{detalle[0].detail.nombre}</td>
                                                </tr>
                                                <tr>
                                                    <th>Rut Cliente : </th>
                                                    <td>{detalle[0].detail.rut}</td>
                                                </tr>
                                                <tr>
                                                    <th>Fecha : </th>
                                                    <td>{this.FormatDate(detalle[0].webpay_transaction_date, 'date')}</td>
                                                </tr>
                                                <tr>
                                                    <th>Hora Transacción : </th>
                                                    <td>{this.FormatDate(detalle[0].webpay_transaction_date, 'hours')}</td>
                                                </tr>
                                                <tr>
                                                    <th>N° Transacción : </th>
                                                    <td>{detalle[0].buy_order}</td>
                                                </tr>
                                                <tr>
                                                    <th>Código de Transacción : </th>
                                                    <td>{detalle[0].detail_mall[0].webpay_authorization_code}</td>
                                                </tr>
                                                <tr>
                                                    <th>4 últimos dígito tarjeta : </th>
                                                    <td>{detalle[0].webpay_card_number}</td>
                                                </tr>
                                                <tr>
                                                    <th>Monto Total : </th>
                                                    <td>${new Intl.NumberFormat().format(detalle[0].amount)}</td>
                                                </tr>
                                                <tr>
                                                    <th>Tipo de Pago : </th>
                                                    <td>{this.TraduccionTypeCode(detalle[0].detail_mall[0].webpay_payment_type_code)}</td>
                                                </tr>
                                                <tr>
                                                    <th>Forma de Pago : </th>
                                                    <td>Transbank</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <table className="table-detalle">
                                        <thead>
                                            <tr>
                                                <th>Código de Comercio</th>
                                                <th>N° Contrato</th>
                                                <th>Tipo</th>
                                                <th>Cuota</th>
                                                {/*<th>Tipo de Pago</th>*/}
                                                <th>Monto</th>
                                                <th>Estado</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {detalle[0]?.detail_cuota.map((item, index) => (
                                                item.monto_cuota > 0 ? (
                                                    <>
                                                        <tr key={index}>
                                                            <td>{item.codigo_comercio}</td>
                                                            <td>{item.numero_contrato}</td>
                                                            <td>{item.concepto_recaudacion}</td>
                                                            <td>{item.numero_cuota}</td>
                                                            {/*<td>{item.webpay_payment_type_code}</td> bgsendero bgsendero-success */}
                                                            <td>${new Intl.NumberFormat().format(item.monto_cuota)}</td>
                                                            <td><span class={this.state.collapses[index].class}>{this.state.collapses[index].status}</span><span className={this.state.collapses[index].view === true ? 'label-sendero-error' : 'd-none'} onClick={() => { this.setToogleCollapse(index,this.state.collapses[index].view)}}>Ver</span></td>
                                                        </tr>
                                                        <tr className={this.state.collapses[index].isopen === true ? 'inherit' : 'd-none'}>
                                                            <th colSpan={6}>
                                                            <Collapse isOpen={this.state.collapses[index].isopen}>
                                                                    <Card className='card-detalle-pago'>
                                                                        <CardBody>
                                                                            <div className="d-flex">    
                                                                                <img src={Alert} alt="" className="card-detalle-pago__imagen" />
                                                                                <ul className="card-detalle-pago__caracteristicas">
                                                                                    <li>Las posibles causas de este rechazo son:</li>
                                                                                    <li>Error en el ingreso de los datos de su tarjeta de crédito o débito (fecha y/o código de seguridad).</li>
                                                                                    <li>Su tarjeta de crédito o débito no cuenta  con el cupo necesario  para cancelar la compra.</li>
                                                                                    <li>Tarjeta aún no habilitada en el sistema financiero.</li>
                                                                                </ul>
                                                                            </div>
                                                                        </CardBody>
                                                                    </Card>
                                                                </Collapse>
                                                            </th>
                                                        </tr>
                                                    </>
                                                    
                                                ) : null
                                            ))}
                                        </tbody>
                                    </table>
                                </Col><br /><br />
                            </Row>
                            <Row>
                                <Col>
                                    <br />
                                    <a href={detalle[0].front_domain} className="btn btn-verde button btn btn-secondary">Volver</a>
                                </Col>
                            </Row>
                        </Container>
                    ) : (
                        <Container>
                          <Row>
                            <Col>
                              <div className="text-center mb-3">
                                <h2 className="text-title-detalle-pago">PAGO RECHAZADO</h2>
                              </div>
                              {/* <div>
                                                        <p className="mis-datos"><strong>Mis Datos</strong></p>
                                                    </div> */}
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <table className="table-detalle">
                                <thead style={{ backgroundColor: '#00a5c8' , color: '#ffffff', textAlign: 'center' }}>
                                  <tr style={{ backgroundColor: '#00a5c8' , color: '#ffffff', textAlign: 'center' }}>
                                    <th style={{ backgroundColor: '#00a5c8' , color: '#ffffff', textAlign: 'center' }}>Código de Comercio</th>
                                    <th style={{ backgroundColor: '#00a5c8' , color: '#ffffff', textAlign: 'center' }}>N° Contrato</th>
                                    <th style={{ backgroundColor: '#00a5c8' , color: '#ffffff', textAlign: 'center' }}>Tipo</th>
                                    <th style={{ backgroundColor: '#00a5c8' , color: '#ffffff', textAlign: 'center' }}>Cuota</th>
                                    {/*<th>Tipo de Pago</th>*/}
                                    <th style={{ backgroundColor: '#00a5c8' , textAlign: 'center' , color: '#ffffff'}}>Monto</th>
                                    <th style={{ backgroundColor: '#00a5c8'  , textAlign: 'center' , color: '#ffffff'}}>Estado</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {detalle[0]?.detail_cuota.map((item, index) => (
                                    item.monto_cuota > 0 ? (
                                      <>
                                        <tr key={index}>
                                          <td>{item.codigo_comercio}</td>
                                          <td>{item.numero_contrato}</td>
                                          <td>{item.concepto_recaudacion}</td>
                                          <td>{item.numero_cuota}</td>
                                          {/*<td>{item.webpay_payment_type_code}</td> bgsendero bgsendero-success */}
                                          <td>${new Intl.NumberFormat().format(item.monto_cuota)}</td>
                                          {/* <td><span class={this.state.collapses[index].class}>{this.state.collapses[index].status}</span><span className={this.state.collapses[index].view === true ? 'label-sendero-error' : 'd-none'} onClick={() => { this.setToogleCollapse(index, this.state.collapses[index].view) }}></span></td> */}
                                          <td><span class={this.state.collapses[index].class}>{this.state.collapses[index].status}</span><span className={this.state.collapses[index].view === true ? 'label-sendero-error' : 'd-none'} onClick={() => { this.setToogleCollapse(index,this.state.collapses[index].view)}}></span></td>
                                        </tr>                   
                                        <tr className={'inherit'}>
                                          <th colSpan={6} className="clsViewExterno">
                                            <Collapse isOpen={true}>
                                              <Card className='card-detalle-pago'>
                                                <CardBody>
                                                  <div className="d-flex">
                                                    <img src={Alert} alt="" className="card-detalle-pago__imagen" style={{ marginRight: '25px' }} />
                                                    <ul className="card-detalle-pago__caracteristicas">
                                                      <li>Las posibles causas de este rechazo son:</li>
                                                      <li>Error en el ingreso de los datos de su tarjeta de crédito o débito (fecha y/o código de seguridad).</li>
                                                      <li>Su tarjeta de crédito o débito no cuenta  con el cupo necesario  para cancelar la compra.</li>
                                                      <li>Tarjeta aún no habilitada en el sistema financiero.</li>
                                                    </ul>
                                                  </div>
                                                </CardBody>
                                              </Card>
                                            </Collapse>
                                          </th>
                                        </tr>
                                      </>
                
                                    ) : null
                                  ))}
                                </tbody>
                              </table>
                            </Col><br /><br />
                          </Row>
                          <Row>
                            <Col>
                              <br />
                              <Link
                                to={"/"}
                                className="btn btn-verde button btn btn-secondary"
                              >
                                Volver
                              </Link>
                            </Col>
                          </Row>
                        </Container>
                      )
                )}
            </>
        );
    }
}
export default DetailPago;