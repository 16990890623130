import React, { useState } from "react";
//import BannerCremacionCompra from "../../../assets/images/Cremacion/banner_cremacion_compra.png"
// import BannerCremacionCompra from "../../../assets/images/eccomerce/banner_ecommerce_cremacion_1920x240.jpg"
// import BannerCremacionCompraMobile from "../../../assets/images/eccomerce/banner_cremacion_mobile_main.jpg"

// import BannerCremacionCompra from "../../../assets/images/eccomerce/banner_desktop_ecom_final.png";
// import BannerCremacionCompra from "../../../assets/images/banner_desktop_11032024.jpg";
// import BannerCremacionCompraMobile from "../../../assets/images/banner_mobile_11032024.jpg";

// import BannerCremacionCompra from "../../../assets/images/banner_desktop_08042024.jpg";
// import BannerCremacionCompraMobile from "../../../assets/images/banner_mobile_08042024.jpg";

import BannerCremacionCompra from "../../../assets/images/banner_desktop_19062024.jpg";
import BannerCremacionCompraMobile from "../../../assets/images/banner_mobile_19062024.jpg";

import "./banner.scss";
import {
  Carousel,
  CarouselItem
} from "reactstrap";

const BannerMain = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const items = [
    {
      caption: "Sample Caption One",
      src: BannerCremacionCompra,
      altText: "Banner Compra en Linea",
    },
  ];

  const itemLength = items.length - 1;

  // Previous button for Carousel
  const previousButton = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? itemLength : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  // Next button for Carousel
  const nextButton = () => {
    if (animating) return;
    const nextIndex = activeIndex === itemLength ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  // Carousel Item Data
  const carouselItemData = items.map((item) => {
    return (
      <CarouselItem
        key={item.src}
        onExited={() => setAnimating(false)}
        onExiting={() => setAnimating(true)}
      >
        <img src={item.src} alt={item.altText} style={{ width: 100 + "%" }} />
      </CarouselItem>
    );
  });

  return (
    <>
      <section className="mb-5 d-none d-sm-block d-xs-none d-md-block cls-top-conditional" >
      {/* <div className="home-page"> */}
        <Carousel
          previous={previousButton}
          next={nextButton}
          activeIndex={activeIndex}
        >
          {carouselItemData}
        </Carousel>
        </section>
        <section
          className="mb-5 d-block d-xs-block d-sm-none d-md-none"
        >
          <Carousel
          previous={previousButton}
          next={nextButton}
          activeIndex={activeIndex}
        >
          {items.map((item) => {
            return (
            <CarouselItem
                key={item.src}
                onExited={() => setAnimating(false)}
                onExiting={() => setAnimating(true)}
            >
                <img src={BannerCremacionCompraMobile} alt={item.altText} style={{ width: 100 + "%" }} />
            </CarouselItem>
            );})}
        </Carousel>
        </section>
      {/* </div> */}
    </>
  );
};

export default BannerMain;
