import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import checkverde from './../../assets/images/icons/checkverde.svg';

const planes = [
    {
        name: 'Cremación ',
        name_bold: 'Estándar 1',
        precio: 96000,
        uf: 30,
        description: [
            {
                name: 'Servicio de Cremación que incluye 1 cremación'
            },
            {
                name: '1 ánfora Estándar y 1 ceremonia de despedida'
            },
            {
                name: '1 ceremonia de despedida y Columbario con capacidad para 0 ánforas'
            }
        ]
    },
    {
        name: 'Cremación ',
        name_bold: 'Ángeles',
        precio: 96000,
        uf: 25,
        description: [
            {
                name: 'Servicio de Cremación que incluye 1 cremación'
            },
            {
                name: '1 ánfora Párvulo y 1 ceremonia de despedida'
            },
            {
                name: '1 ceremonia de despedida y Columbario con capacidad para 0 ánforas'
            }
        ]
    },
    {
        name: 'Cremación',
        name_bold: 'Madre Tierra',
        precio: 99000,
        uf: 35,
        description: [
            {
                name: 'Servicio de Cremación que incluye 1 cremación'
            },
            {
                name: '1 ánfora Ecológica y 1 ceremonia de despedida'
            },
            {
                name: '1 ceremonia de despedida y Columbario con capacidad para 0 ánforas'
            }
        ]
    },
    {
        name: 'Cremación',
        name_bold: 'Especial',
        precio: 99000,
        uf: 35,
        description: [
            {
                name: 'Servicio de Cremación que incluye 1 cremación'
            },
            {
                name: '1 ánfora Especial y 1 ceremonia de despedida'
            },
            {
                name: '1 ceremonia de despedida y Columbario con capacidad para 0 ánforas'
            }
        ]
    },
    {
        name: 'Cremación',
        name_bold: 'Superior',
        precio: 99000,
        uf: 39,
        description: [
            {
                name: 'Servicio de Cremación que incluye 1 cremación'
            },
            {
                name: '1 ánfora Superior y 1 ceremonia de despedida'
            },
            {
                name: '1 ceremonia de despedida y Columbario con capacidad para 0 ánforas'
            }
        ]
    },
    {
        name: 'Cremación',
        name_bold: 'Estándar 2',
        precio: 99000,
        uf: 58,
        description: [
            {
                name: 'Servicio de Cremación que incluye 2 cremaciones'
            },
            {
                name: '2 ánforas Estándar y 2 ceremonias de despedida'
            },
            {
                name: '2 ceremonias de despedida y Columbario con capacidad para 0 ánforas'
            }
        ]
    },
    {
        name: 'Cremación',
        name_bold: 'Estándar 3',
        precio: 99000,
        uf: 86,
        description: [
            {
                name: 'Servicio de Cremación que incluye 3 cremaciones'
            },
            {
                name: '3 ánforas Estándar y 3 ceremonias de despedida'
            },
            {
                name: '3 ceremonias de despedida y Columbario con capacidad para 0 ánforas'
            }
        ]
    }

]

const Plan = () => {
    const [selectPlan, selectedPlan] = useState(0);
    const [selectComparePlan, selectedComparePlan] = useState(0);
    const [modal, setModal] = useState(false);
    const openComparePlan = (key) => {
        setModal(true)
        selectedComparePlan(key)
    }
    const setPlan = () => {
        selectedPlan(selectComparePlan)
        setModal(false)
    }
    return (
        <>
            <div className="row align-items-end my-4">
                <div className="col-12 offset-md-2 col-md-4 box box-plan">
                    <div className="p-3">
                        <p className="title">{planes[selectPlan].name}<br /><strong className="green">{planes[selectPlan].name_bold}</strong></p>
                        <div className="description my-3">
                            {planes[selectPlan].description.map((value, key) => (
                                <p key={key}><img src={checkverde} alt="" className="check-green" /> {value.name}</p>
                            ))}
                        </div>
                    </div>
                    <div className="row align-items-end mb-3">
                        <div className="col-12 col-md-6">
                            <button className="btn btn-blue">
                                Leer más
                            </button>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="description-price">
                                <p className="description-price__desde">desde</p>
                                <p className="description-price__amount"><span>{planes[selectPlan].uf} UF</span></p>
                                <p className="description-price__texto">* valor variable</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-4">
                    <div className="px-2 pt-3 pt-md-0 option-plan">
                        {planes.map((value, key) => (
                            selectPlan !== key && (
                                <p onClick={() => openComparePlan(key)}>Conoce nuestro Plan {value.name} {value.name_bold}</p>
                            )
                        ))}
                    </div>
                </div>
            </div>

            <Modal isOpen={modal} toggle={(e) => setModal(!modal)} backdrop={'static'} fade={false} size='lg' className="modal-plan">
                <ModalHeader toggle={(e) => setModal(!modal)}></ModalHeader>
                <ModalBody>
                    <>
                        <div className="row">
                            <div className="col-12 text-center">
                                <h2 className="text-center my-3">CAMBIAR DE <strong>PLAN</strong></h2>
                                <p>Seleccionaste conocer nuestro plan de Cremación Especial ¿Deseas cambiar de plan?</p>
                            </div>
                        </div>
                        <div className="row justify-content-center align-items-center my-4">
                            <div className="col-12 offset-md-1 col-md-5 mb-3 mb-md-0">
                                <div className="box box-plan">
                                    <div className="p-3">
                                        <p className="title">{planes[selectPlan].name}<br /><strong className="green">{planes[selectPlan].name_bold}</strong></p>
                                        <div className="description my-3">
                                            {planes[selectPlan].description.map((value, key) => (
                                                <p key={key}><img src={checkverde} alt="" className="check-green" /> {value.name}</p>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-5">
                                <div className="box box-plan">
                                    <div className="p-3">
                                        <p className="title">{planes[selectComparePlan].name}<br /><strong className="blue">{planes[selectComparePlan].name_bold}</strong></p>
                                        <div className="description my-3">
                                            {planes[selectComparePlan].description.map((value, key) => (
                                                <p key={key}><img src={checkverde} alt="" className="check-green" /> {value.name}</p>
                                            ))}
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </>
                </ModalBody>
                <ModalFooter>
                    <div className="container">
                        <div className="row">
                            <div className="col-6 text-right"><button className="btn btn-red" onClick={() => setModal(false)}>Volver</button></div>
                            <div className="col-6 text-left"><button className="btn btn-green" onClick={() => setPlan()}>Cambiar de plan</button></div>
                        </div>
                    </div>
                </ModalFooter>
            </Modal>
        </>
    )

}
export default Plan;