import React, { Component } from 'react';
import { Collapse, Row, Col, Button } from 'reactstrap';
import { BeatLoader } from 'react-spinners';
import Api from '../../../service/Api';
import Session from '../../../service/Session';
import Layout from '../../Layout';
import DetallePie from './Components/DetallePie';
import DocumentPie from './Components/DocumentPie';
import Monto from './Components/Monto';
import AceptarCondiciones from './Components/AceptarCondiciones';
import Vineta from '../../../assets/images/vineta_up.png';
class ListPie extends Component {
	constructor(props) {
		super(props)
		this.state = {
			rut: Session.getInfoUser().rut,
			ContratosPie: '',
			itemsManzana: '',
			disabledEnviar: {},
			disabledMonto: {},
			mostrarAceptar: {},
			collapse: 0,
			valoresData: {},
			monto: {},
			amount: 0,
			token_ws: 0,
			url: '',
			loading: false,
		}
		this.setForm = this.setForm.bind(this);
		this.aceptar = this.aceptar.bind(this);
		this.InsertarValor = this.InsertarValor.bind(this);
		this.toggle = this.toggle.bind(this);
	}

	async componentDidMount() {
		this.getContratosCCU();
	}
	async getContratosCCU() {
		let dataApi = {};
		dataApi.rut = this.state.rut;
		this.setState({
			loading: true,
		});
		let data = await Api.getContratosCCU(dataApi);
		this.setState({
			ContratosPie: data,
			loading: false,
		});
	}
	async aceptar(index, dataContrato) {
		this.setState({
			loading: true,
		});
		const dataApi = {};
		var mAceptar = this.state.mostrarAceptar;
		var dMonto = this.state.disabledMonto;
		dataApi.CONTRATO = dataContrato.CONTRATO;
		dataApi.CIA = dataContrato.CIA;
		dataApi.PARQUE = dataContrato.PARQUE;
		dataApi.SERIE = dataContrato.SERIE;
		dataApi.rut = this.state.rut;
		mAceptar[index] = dataContrato.CONTRATO;
		dMonto[index] = dataContrato.CONTRATO;
		let data = await Api.aceptarCondiciones(dataApi);
		if (parseInt(data.RESULTADO) === 1) {
			this.setState({
				mostrarAceptar: mAceptar,
				disabledMonto: dMonto,
				loading: false,
			});
		}
	}
	InsertarValor(e, index, contrato) {
		var valores = {};
		var suma = 0;
		valores['valor'] = parseInt(e.target.value);
		valores['contrato'] = contrato;
		var valoresData = this.state.valoresData;
		valoresData[index] = valores;
		for (var count in valoresData) {
			suma += parseInt(valoresData[count].valor);
		}
		this.setState({
			amount: parseInt(suma)
		});
	}
	async setForm() {
		var dataApi = {};
		dataApi.rut = this.state.rut;
		dataApi.amount = this.state.amount;
		dataApi.cuotasSeleccionadas = JSON.stringify(this.state.valoresData);
		let response = await Api.PagarPieWebpay(dataApi);
		this.setState({
			token_ws: response.token_ws,
			url: response.next_page
		});
		document.getElementById("form_webpay_pie").submit();
	}
	toggle(e, tab) {
		e.preventDefault();
		if (this.state.collapse !== tab) {
			this.setState({
				collapse: tab
			});
		} else {
			this.setState({
				collapse: 0
			});
		}
	}
	render() {
		const { ContratosPie, rut } = this.state;
		return (
			<>
				<form name="form_webpay_pie" action={this.state.url} method="POST" id="form_webpay_pie">
					<input type="hidden" name="token_ws" value={this.state.token_ws} />
				</form>
				<Layout name="Aceptar y Pagar Pie Contrato">
					{this.state.loading && (
						<div className="grilla-loading">
							<BeatLoader
								sizeUnit={"px"}
								size={15}
								color={'#454847'}
								loading={this.state.loading}
							/>
							<p>Cargando...</p>
						</div>
					)}
					{!this.state.loading && (
						<>{
							ContratosPie.length > 0 ? (
								<>
									{ContratosPie.map((item, index) => (
										item.detallePie && item.detallePie.length > 0 && (
											<>
												<div className="content-pie" key={index}>
													<h5 onClick={(e) => { this.toggle(e, index) }} >Aceptar y Pagar Pie Contrato {item.detallePie[0].CTAS_IND_ESTADO_PAGO === 'P' ? ' -- PAGADO' : ''} <img src={Vineta} className="float-right icon-list icon-pie" alt="" /></h5>
													<Collapse isOpen={parseInt(this.state.collapse) === index ? true : false}>
														<div className="container">
															<DetallePie
																nombre={item.NOMBRE_TITULAR}
																contrato={item.CONTRATO} rut={rut}
																finance={typeof item.datos_financiamiento[0] === 'undefined' ? '' : item.datos_financiamiento[0]}
																product={item.datos_prcm[0]}
																mnzn={item.datos_mnzn} />
															<DocumentPie
																contrato={item.CONTRATO}
																rut={rut} />
															<AceptarCondiciones
																index={index} rut={rut}
																contrato={item}
																aceptar={this.aceptar} />
														</div>
														<br />
														<table className="table">
															<thead>
																<tr className="header">
																	<th scope="col">Fecha de Vencimiento</th>
																	<th scope="col">Estado de pago</th>
																	<th scope="col">Interes</th>
																	<th scope="col">Recargo</th>
																	<th scope="col">Ultimo pago</th>
																	<th scope="col">Saldo pendiente</th>
																	<th scope="col">Monto a pagar</th>
																</tr>
															</thead>
															<tbody>
																<tr className="CV_MNT">
																	<td>{item.detallePie[0].CTAS_FCH_VENCIMIENTO}</td>
																	<td>{item.detallePie[0].CTAS_IND_ESTADO_PAGO}</td>
																	<td>{item.detallePie[0].INTERESES}</td>
																	<td>{item.detallePie[0].RECARGOS}</td>
																	<td>{item.detallePie[0].ULT_PAGO}</td>
																	<td>{item.detallePie[0].ACUMULADO}</td>
																	<td>
																		<Monto
																			valor={typeof item.detallePie[0].APAGAR === 'undefined' ? '' : item.detallePie[0].APAGAR}
																			correlaivo={index}
																			rut={rut}
																			contrato={item.CONTRATO}
																			activarInput={this.state.disabledMonto[index] === item.CONTRATO}
																			InsertarValor={this.InsertarValor} />
																	</td>
																</tr>
															</tbody>
														</table>
														<p>Usted como Cliente, puede pagar el pie durante la vigencia de esta cotización. En tal caso, de no concurrir luego las partes a la celebración del contrato, La Empresa Sendero le reembolsará la totalidad del monto pagado por este concepto.</p>
													</Collapse>
												</div>
											</>
										)
									))}
									<Row>
										<Col md={12} className="text-right content-pagar">
											<div className="content-text-pagar">
												<h3>Total a pagar</h3>
											</div>
											<div className="content-pagar-valor">
												<div className="content-precio">
													<p>${new Intl.NumberFormat().format(this.state.amount)}.-</p>
												</div>
												<div className="content-button">
													<Button className="btn btn-verde button btn-secondary" onClick={this.setForm} disabled={this.state.amount === 0 ? true : false}>PAGAR</Button>
												</div>
											</div>
										</Col>
									</Row>
								</>
							) : (
									<><h1>No tiene contratos vigentes</h1></>
								)
						}

						</>
					)}
				</Layout>
			</>
		)
	}
}
export default ListPie;