import React, { useState, useEffect } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import COBRE from './../../assets/images/Cremacion/anforas/COBRE.jpg';
import CIPRES_CAOBA from './../../assets/images/Cremacion/anforas/COFRE_PINO_CAOBA.png';
import CIPRES_ROBLE from './../../assets/images/Cremacion/anforas/CIPRES_CAOBA.jpg';
import PAULOWNIA from './../../assets/images/Cremacion/anforas/PAULOWNIA.jpg';
import PLATA from './../../assets/images/Cremacion/anforas/PLATA.jpg';
import ARBOL_DE_VIDA from './../../assets/images/Cremacion/anforas/ARBOL_DE_VIDA.jpg';
import ARAYAN from './../../assets/images/Cremacion/anforas/CIPRES_ARAYAN.jpg';
import BIOS from './../../assets/images/Cremacion/anforas/BIOS.jpg';
import NU from './../../assets/images/Cremacion/anforas/NU.jpg';
import ARENAE from './../../assets/images/Cremacion/anforas/ARENAE.jpg';
import SAMSARA from './../../assets/images/Cremacion/anforas/SAMSARA.jpg';
import MADRE_PERLA from './../../assets/images/Cremacion/anforas/MADRE_PERLA.jpg';
import ONYX_GRECO from './../../assets/images/Cremacion/anforas/ONYX_GRECO.jpg';

import SUPERIOR_ROYAL_BIRD from './../../assets/images/Cremacion/anforas/SUPERIOR_ROYAL_BIRD.jpeg';
import ANFORA_DORADA_FINAL from './../../assets/images/Cremacion/anforas/anfora_dorada_final.jpg';

import ANFORA_GOLD_LOVE from './../../assets/images/Cremacion/anforas/2022/Anfora_Gold_Love.png';
import ANFORA_ARBOL_DE_VIDA from './../../assets/images/Cremacion/anforas/2022/Anfora_Arbol_De_Vida.png';
import ANFORA_ROYAL_BIRD from './../../assets/images/Cremacion/anforas/2022/Anfora_Royal_Bird.png';

import ANFORA_MADERA_PERLA from './../../assets/images/Cremacion/anforas/2022/anfora_madera_perla.png';
import ANFORA_2023_ROYAL_BIRD from './../../assets/images/Cremacion/anforas/2023/anfora_royal_bird.png';

import ANFORA_ONYX_GRECO_350_350 from './../../assets/images/Cremacion/anforas/2023/onyx_350_350.png';

import ANFORA_SILVER_BIRD_20240327 from './../../assets/images/Cremacion/anforas/2024/anfora_silver_bird_20240327.png';
import ANFORA_GOLD_LOVE_20240327  from './../../assets/images/Cremacion/anforas/2024/anfora_gold_love_20240327.png';

const anforas = [
  {
    category: 'Estándar',
    products: [
      // {
      //     "name": "Ánfora Gold Love",
      //     "stock": "",
      //     "disponible": "",
      //     "image": ANFORA_GOLD_LOVE,
      // },
      // {
      //     "name": "Ánfora Árbol de Vida",
      //     "stock": "",
      //     "disponible": "",
      //     "image": ANFORA_ARBOL_DE_VIDA,
      // },
      // {
      //     "name": "Ánfora Royal Bird",
      //     "stock": "",
      //     "disponible": "",
      //     "image": ANFORA_ROYAL_BIRD,
      // }
      {
        name: 'Ánfora Clásica de Cobre',
        stock: '',
        disponible: '',
        image: COBRE,
      },
      {
        name: 'Cofre Pino Caoba',
        stock: '',
        disponible: '',
        image: CIPRES_CAOBA,
      },
      // {
      //     "name": "Cofre Pino Roble",
      //     "stock": "",
      //     "disponible": "",
      //     "image": CIPRES_ROBLE,
      // }
      {
        name: 'Ánfora Dorada',
        stock: '',
        disponible: '',
        image: ANFORA_DORADA_FINAL,
      },
    ],
  },
  {
    category: 'Especial',
    products: [
      {
        name: 'Ánfora Pawlonia',
        stock: '',
        disponible: '',
        image: PAULOWNIA,
      },
      // {
      //   name: 'Cofre Arayan',
      //   stock: '',
      //   disponible: '',
      //   image: ARAYAN,
      // },
      {
        name: 'Ánfora Silver Bird',
        stock: '',
        disponible: '',
        image: ANFORA_SILVER_BIRD_20240327,
      },
      {
        name: 'Ánfora Plata',
        stock: '',
        disponible: '',
        image: PLATA,
      },
    ],
  },
  {
    category: 'Superior',
    products: [
      // {
      //     "name": "Ánfora Royal Bird",
      //     "stock": "",
      //     "disponible": "",
      //     //"image": ARBOL_DE_VIDA,
      //     "image": SUPERIOR_ROYAL_BIRD,
      // },
      // {
      //     "name": "Ánfora Madre Perla",
      //     "stock": "",
      //     "disponible": "",
      //     "image": MADRE_PERLA,
      // },
      // {
      //     "name": "Ánfora Onyx Greco",
      //     "stock": "",
      //     "disponible": "",
      //     "image": ONYX_GRECO,
      // }
      // {
      //   name: 'Onyx Greco',
      //   stock: '',
      //   disponible: '',
      //   image: ANFORA_ONYX_GRECO_350_350,
      // },
      
      {
        name: 'Ánfora Gold Love',
        stock: '',
        disponible: '',
        image: ANFORA_GOLD_LOVE_20240327,
      },
      
      // {
      //   name: 'Ánfora Gold Love',
      //   stock: '',
      //   disponible: '',
      //   image: ANFORA_GOLD_LOVE,
      // },
      {
        name: 'Ánfora Árbol de Vida',
        stock: '',
        disponible: '',
        image: ANFORA_ARBOL_DE_VIDA,
      },
      // {
      //     "name": "Ánfora Royal Bird",
      //     "stock": "",
      //     "disponible": "",
      //     "image": ANFORA_ROYAL_BIRD,
      // }
      {
        name: 'Ánfora Royal Bird',
        stock: '',
        disponible: '',
        image: ANFORA_2023_ROYAL_BIRD,
      },
    ],
  },
  {
    category: 'Madre Tierra',
    products: [
      {
        name: 'Ánfora Bios',
        stock: '',
        disponible: '',
        image: BIOS,
      },
      {
        name: 'Ánfora Nu',
        stock: '',
        disponible: '',
        image: NU,
      },
      {
        name: 'Ánfora Arenae',
        stock: '',
        disponible: '',
        image: ARENAE,
      },
      {
        name: 'Ánfora Samsara',
        stock: '',
        disponible: '',
        image: SAMSARA,
      },
    ],
  },
];

const Anforas = () => {
  // window.scrollTo(0, 0);
  const [statusTab, setStatusTab] = useState(0);
  const toggle = (index) => {
    setStatusTab(index);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="row my-5">
        <div className="col-12 text-center">
          <h2>
            Descubre nuestra gran <strong>variedad de ánforas</strong>
          </h2>
          <p>
            Contamos con diferentes categorías de ánforas, para entregar una
            opción para cada tipo de familia. Desde la más tradicional y
            sencilla, hasta las más modernas y ecológicas.
          </p>
        </div>
      </div>
      <div className="tab-anforas">
        <Nav tabs>
          {anforas.map((val, index) => (
            <NavItem>
              <NavLink
                onClick={() => {
                  toggle(index);
                }}
              >
                {val.category}
              </NavLink>
            </NavItem>
          ))}
        </Nav>
        <TabContent activeTab={statusTab}>
          {anforas.map((val, index) => (
            <TabPane tabId={index}>
              <div className="row my-4">
                {val.products.map((value, key) => (
                  <div className="col-12 col-md-3 px-2 text-center" key={key}>
                    <div className="box mb-2">
                      <div className="box__title_anfora">
                        <p>{value.name}</p>
                      </div>
                      <div className="box__content p-2">
                        <img
                          src={value.image}
                          alt=""
                          className="img-fluid mb-2 image"
                        />
                        <p className="mb-2">
                          <strong>{value.name}</strong>
                        </p>
                        <p className="description">{value.description}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </TabPane>
          ))}
        </TabContent>
      </div>
    </>
  );
};
export default Anforas;
