import React from "react";

import mas_informados from "../../../../assets/images/pseudobanner/banner_mas_informados_mobile.png";
import './pseudobanner.scss';
import { useEffect } from "react";

const PesudoBanner = () => {

  const handleClick2 = () => {
    const imageSections = document.querySelectorAll('.image-section-click-mb');
    const imageLink = document.querySelectorAll('.image-link-goto-mb');
    const image = document.querySelector('.img-pseudo-banner-mb');
    const imageWidth = image.clientWidth;

    imageSections.forEach((section, index) => {
      section.style.width = `${imageWidth*33/100}px`;
      section.style.height = `${image.clientHeight/2}px`;

      try {
        imageLink[index].style.width = `${imageWidth*33/100}px`;  
      } catch (error) {
        
      }
  });
 }

  useEffect(() => {
    
    const handleClickx = () => {
      // 👇️ refers to the image element
      // console.log('registrandose evento click');
      const imageSections = document.querySelectorAll('.image-section-click-mb');
      const imageLink = document.querySelectorAll('.image-link-goto-mb');
      const image = document.querySelector('.img-pseudo-banner-mb');
      const imageWidth = image.clientWidth;

      imageSections.forEach((section, index) => {
        section.style.width = `${imageWidth*33/100}px`;
        section.style.height = `${image.clientHeight/2}px`; 
        try {
          imageLink[index].style.width = `${imageWidth*33/100}px`;  
        } catch (error) {
          
        }
    });
  }
    if (document.readyState === 'complete') {
      handleClickx();
    } else {
      window.addEventListener('load', handleClickx);
      return () => window.removeEventListener('load', handleClickx);
    }

  }, []);

  return (
    <>
      <br />
      <div className="image-container-clicked-mb">
        <div className="image-section-click-mb"  style={{left: '0%'}}>
            <a className="image-link-goto-mb" href="https://masinformados.sendero.cl/productos-servicios-y-precios/" target="_blank" rel="noopener noreferrer">{}</a>
        </div>
        <div className="image-section-click-mb"  style={{left: '33.33%'}}>
            <a className="image-link-goto-mb" href="https://masinformados.sendero.cl/precios-sendero/" target="_blank" rel="noopener noreferrer">{}</a>
        </div>
        <div className="image-section-click-mb" style={{left: '66.66%'}}>
            <a className="image-link-goto-mb" href="https://masinformados.sendero.cl/contratos-y-reglamentos/" target="_blank" rel="noopener noreferrer">{}</a>
        </div>
        <img src={mas_informados} className="img-fluid img-pseudo-banner-mb" alt="Imagen dividida"  style={{cursor: 'pointer'}} onClick={handleClick2} />
      </div>
    </>
  );
};

export default PesudoBanner;
