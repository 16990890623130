import React, { Component } from 'react';
import axios from 'axios';

import MenuMega from './Components/MenuMega'
import MenuActions from './Components/MenuActions'
import MenuTab from './Components/MenuTabs'
import MenuHorizontal from './Components/MenuHorizontal'
import './MegaMenu.scss'

class MegaMenu extends Component {
    constructor(props) {
        super(props);
        this.state = { dataApi: {} };
    }
    componentWillMount() {
        this.getDataMegaMenu();
    }
    async getDataMegaMenu() {
        if (localStorage.getItem("update") === null) {
            await axios.get('https://sendero-api.gux.cl/api/web/megamenu')
                .then(res => {
                    localStorage.setItem('menu', JSON.stringify(res));
                    localStorage.setItem('update', 1);
                    if (res.data !== '') {
                        this.setState({
                            dataApi: res,
                        });
                    } else {
                        console.info("menu no llegó");
                    }
                })
        } else {
            this.setState({
                dataApi: JSON.parse(localStorage.getItem("menu"))
            });
        }
    }
    render() {
        const { dataApi } = this.state;
        const { data } = dataApi;
        if (typeof data === 'undefined') {
            return false;
        }
        const { accion, horizontal, megamenu, tab } = data;
        return (
            <>
                {dataApi.status === 200 && (
                    <>
                        <div className="menu-fixed">
                            {this.props.alerta}
                            <div className="mega-menu">
                                <div className="mega-menu__top">
                                    <div className="row">
                                        <MenuMega ObjectData={megamenu} />
                                        <MenuTab ObjectData={tab} />
                                        <MenuActions ObjectData={accion} />
                                    </div>
                                    <div className="container">
                                        <div className="row">
                                            <MenuHorizontal ObjectData={horizontal} ObjectDataMobile={megamenu} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </>
        );
    }
}

export default MegaMenu;