import React, { Component } from 'react';
import { Col, Button, Form, FormGroup, Input, Alert } from 'reactstrap';
import Api from '../../service/Api';
import Validate from '../../service/Validate';
import FileUploader from '../../service/FileUploader';

class Formulario extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Canal:  this.getCanal(),
            Nombre: '',
            Email: '',
            Area: 0,
            Sucursal: 0,
            Mensaje: '',
            customFileLang: '',
            txtMsj: '',
            AlertClass: '',
            disabledButton: false,
            nameButton: 'ENVIAR',
            destino_email: '60d61is2@robot.zapier.com',
            type_form: this.props.etico === true ? 'Formularios Canal de denuncias etico' : 'Formularios Canal de denuncias'
        }
        this.setForm = this.setForm.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }
    async setForm() {

        let mensaje = "";
        let rules = {
            texto: 'Nombre',
            email: 'Email',
            selector1: 'Area',
            selector2: 'Sucursal'
        };
        let validate = {};
        for (var [type, input] of Object.entries(rules)) {
            let resul = Validate.ValidateForm(this.state[input], type.replace(/[0-9]/g, ''), input)
            if (resul !== '') {
                mensaje += resul
                validate[input] = [resul];
            }
        }
        Validate.validate(document.getElementsByClassName("form-canal-denuncias-01")[0], validate)
        if (mensaje !== "") {
            this.setState({ txtMsj: "Por favor revise los siguientes campos:\n\n" + mensaje, AlertClass: "alert-danger" });
            return false;
        }
        const fileObject = this.refs.uploader.state.imageSrc;
        this.setState({ file: fileObject });

        this.setState({ disabledButton: true, nameButton: 'ENVIANDO..' });
        const response = await Api.setForm(this.state);
        if (response.result === 'true') {
            this.setState({
                Nombre: '',
                Rut: '',
                Email: '',
                Telefono: '',
                Mensaje: '',
                txtMsj: "Correo enviado correctamente",
                AlertClass: "alert-success",
                nameButton: 'ENVIAR',
                disabledButton: false
            });
        } else {
            this.setState({
                txtMsj: "Ocurrió un problema, favor intentar nuevamente",
                AlertClass: "alert-danger",
                nameButton: 'ENVIAR',
                disabledButton: false
            });
        }
    }
    handleInputChange(event) {
        this.setState({
            txtMsj: ""
        });
        const target = event.target;
        const value = target.value;
        const name = target.name;
        if (target.name === 'Motivo') {
            this.handleSelectMotivo(event);
        }
        this.setState({
            [name]: value
        });
        if (this.state.Nombre && this.state.Email) {
            this.setState({
                disabledButton: false
            });
        } else {
            this.setState({
                disabledButton: true
            });
        }
    }

    getCanal() {

        // Obtener la cadena de consulta de la URL actual
        const queryParams = new URLSearchParams(window.location.search);

        // Obtener el valor de un parámetro específico (por ejemplo, 'id')
        const canal = queryParams.get('Canal');

        if(canal === "" || canal === null || canal === undefined){
            return "Sendero";

        }

        return canal;

    }
    render() {

        const { etico } = this.props;
        //console.log(etico);
        return (
            <div className="canal-denuncias-form">
                <Form className="form-canal-denuncias-01 px-2" id="form">
                    <FormGroup row>
                        <Col sm={6} className="px-2">
                            <Input type="text" name="Nombre" id="Nombre" placeholder="Nombre Denunciante (Opcional)" onChange={this.handleInputChange} value={this.state.Nombre || ''} />
                        </Col>
                        <Col sm={6} className="px-2">
                            <Input type="text" name="Email" id="Email" placeholder="Correo electrónico (Opcional)" onChange={this.handleInputChange} value={this.state.Email || ''} />
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Col sm={6} className="px-2">
                            <Input type="select" name="Area" id="Area" onChange={this.handleInputChange}>
                                <option value="0">Área donde ocurrió el delito (requerido)</option>
                                <option value="Administración">Administración</option>
                                <option value="Comercial">Comercial</option>
                                <option value="Operaciones">Operaciones</option>
                                {
                                    etico === true ? (<><option value="Otra">Otra</option></>) : (<></>)
                                }
                            </Input>
                        </Col>
                        <Col sm={6} className="px-2">
                            <Input type="select" name="Sucursal" id="Sucursal" onChange={this.handleInputChange}>
                                <option value="0">Sucursal donde ocurrió el delito (requerido)</option><option value="Casa Sendero">Casa Sendero</option>
                                <option value="Mac Iver piso 19">Mac Iver piso 19</option>
                                <option value="Mac Iver piso 18">Mac Iver piso 18</option>
                                <option value="Mac Iver piso 9">Mac Iver piso 9</option>
                                <option value="Mac Iver piso 8">Mac Iver piso 8</option>
                                <option value="Mac Iver piso 6">Mac Iver piso 6</option>
                                <option value="Oficina Las Rejas">Oficina Las Rejas</option>
                                <option value="Oficina Quilpué">Oficina Quilpué</option>
                                <option value="Oficina Rancagua">Oficina Rancagua</option>
                                <option value="Oficina San Antonio">Oficina San Antonio </option>
                                <option value="Oficina San Pablo">Oficina San Pablo</option>
                                <option value="Oficina Valparaíso">Oficina Valparaíso</option>
                                <option value="Oficina Villa Alemana">Oficina Villa Alemana</option>
                                <option value="Oficina Huérfanos">Oficina Huérfanos</option>
                                <option value="Parque Arica">Parque Arica</option>
                                <option value="Parque Iquique">Parque Iquique</option>
                                <option value="Parque Villa Alemana">Parque Villa Alemana</option>
                                <option value="Parque Quilpué">Parque Quilpué </option>
                                <option value="Parque Valparaíso">Parque Valparaíso</option>
                                <option value="Parque San Antonio">Parque San Antonio</option>
                                <option value="Parque Maipú">Parque Maipú</option>
                                <option value="Parque San Bernardo">Parque San Bernardo </option>
                                <option value="Parque Rancagua">Parque Rancagua</option>
                                <option value="Parque Temuco">Parque Temuco</option>
                                <option value="Parque Concepción">Parque Concepción</option>
                                <option value="Servicio Cobranza Externo">Servicio Cobranza Externo</option>
                                {
                                    etico === true ? (<><option value="Otra">Otra</option></>) : (<></>)
                                }
                            </Input>
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Col sm={12} className="px-2">
                            <Input type="textarea" name="Mensaje" id="Mensaje" placeholder="Detalle..." rows="4" cols="50" onChange={this.handleInputChange} value={this.state.Mensaje || ''} />
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Col sm={12} className="px-2">
                            <div className="custom-file"><FileUploader ref="uploader" /></div>
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Col sm={12} className="text-center">
                            {this.state.txtMsj !== "" ? <Alert className={'alert ' + this.state.AlertClass}>{this.state.txtMsj}</Alert> : ""}
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Col sm={12} className="text-left px-2">
                            <Button className="btn btn-verde button" onClick={this.setForm} disabled={this.state.disabledButton}>{this.state.nameButton}</Button>
                        </Col>
                    </FormGroup>
                </Form>
            </div>
        );
    }
}
export default Formulario;