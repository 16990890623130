import React, { Component } from 'react';
import { Collapse, Row, Col, Button } from 'reactstrap';
import Api from './../../../service/Api';
import Session from './../../../service/Session';
import Layout from './../../Layout/';

import ListServicio from './Components/ListServicio';
import ListFinanciamiento from './Components/ListFinanciamiento';
import ListMantencion from './Components/ListMantencion';

class ServicioContratado extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rut: Session.getInfoUser().rut,
            amount: 0,
            cuotasSeleccionadas: {},
            token_ws: 0,
            url: '',
        }
        this.setForm = this.setForm.bind(this);
        this.toggle = this.toggle.bind(this);
    }

    calcularTotal(e, val, Tipo, NContrato) {
        let contrato = this.state.cuotasSeleccionadas
        if (e.target.checked) {
            if (typeof contrato[NContrato] !== 'undefined') {
                contrato[NContrato][Tipo] = val
            } else {
                contrato[NContrato] = {
                    'FA': null,
                    'FV': null,
                    'MA': null,
                    'MV': null
                }
                contrato[NContrato][Tipo] = val
            }
        } else {
            contrato[NContrato][Tipo] = null;
        }
        this.setState({
            amount: e.target.checked ? parseInt(this.state.amount) + parseInt(val) : parseInt(this.state.amount) - parseInt(val),
            cuotasSeleccionadas: contrato
        });
    }

    async setForm() {
        let cuotas = this.state.cuotasSeleccionadas;
        let response = await Api.Pagar(
            {
                rut: this.state.rut,
                amount: this.state.amount,
                cuotasSeleccionadas: JSON.stringify(cuotas),
                tipo: 'servicio'
            }
        );
        this.setState({
            token_ws: response.token_ws,
            url: response.next_page
        });
        document.getElementById("form_webpay").submit();
    }
    toggle(e, tab) {
        e.preventDefault();
        if (this.state.collapse !== tab) {
            this.setState({
                collapse: tab
            });
        } else {
            this.setState({
                collapse: 0
            });
        }
    }
    render() {
        let contratos = Session.getContratos().contrato;
        return (
            <>
                <form name="form_webpay" action={this.state.url} method="POST" id="form_webpay">
                    <input type="hidden" name="token_ws" value={this.state.token_ws} />
                </form>
                <Layout name="Servicio Contratados">
                    {contratos.length > 0 ? (
                        <>
                            <Row>
                                {contratos.map((item, index) => (
                                    <Col sm="12" className="border-top" key={index}>
                                        <div onClick={(e) => { this.toggle(e, index + 1) }} className={(parseInt(this.state.collapse) === (index + 1) ? 'open-list' : '') + ' text-list my-2'}>
                                            <table className="table" id="table-gray">
                                                <thead>
                                                    <tr className="head">
                                                        <th scope="col" width="30%">
                                                            Producto </th>
                                                        <th scope="col" width="30%">
                                                            Detalle
                                                </th>
                                                        <th scope="col" width="40%">
                                                            Descripción
                                                </th>
                                                    </tr>
                                                </thead>
                                            </table>
                                        </div>
                                        <Collapse isOpen={index === 0 ? true : false}>
                                            <ListServicio items={item} />
                                            <ListFinanciamiento items={item} calcularTotal={this.calcularTotal.bind(this)} />
                                            <ListMantencion items={item} calcularTotal={this.calcularTotal.bind(this)} />
                                        </Collapse>
                                    </Col>
                                ))}

                            </Row>
                            <Row>
                                <Col md={12} className="text-right content-pagar">
                                    <div className="content-text-pagar">
                                        <h3>Total a pagar</h3>
                                    </div>
                                    <div className="content-pagar-valor">
                                        <div className="content-precio">
                                            <p>${new Intl.NumberFormat().format(this.state.amount)}.-</p>
                                        </div>
                                        <div className="content-button">
                                            <Button className="btn btn-verde button btn-secondary" onClick={this.setForm} disabled={this.state.amount === 0 ? true : false}>PAGAR</Button>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </>
                    ) : (<><h1>No tiene contratos vigentes</h1></>)
                    }
                </Layout>
            </>
        );
    }
}
export default ServicioContratado;