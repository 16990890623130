import React, { Component, useState } from 'react';
import { Collapse, Spinner, Button } from 'reactstrap';
import Cabecera from './Components/Cabecera';
import './Assets/css/style.scss';
import validator from 'validator';
import TableCuota from './Components/TableCuota';
import TablePie from './Components/TablePie';
import Api from './../service/Api';
import Session from './../service/Session';
import { LogoSendero } from './Components/Logo';
import { RS } from './Components/RS';
import { Footer } from './Components/Footer';
import Adicional from './Components/Adicional';
import casa from './Assets/Images/casa.svg';
import webpayTransbank from './Assets/Images/webpay_transbank.jpg';
import khipu from './Assets/Images/khipu.jpg';
// import bannerMPU from './Assets/Images/bannerpagorapido.jpg';
import bannerMPUX from './Assets/Images/banner_new_pago_rapido.png';
import bannerMPUXDESKTOP from './Assets/Images/banner_new_pago_rapido_mobile.png';
import bannerMPUXMOBILE from './Assets/Images/banner_new_pago_rapido_mobile_375.png';

import PdfPopUp from './Components/PdfPopUp';
import { ViewIvas } from '../config';

import { Modal, ModalBody } from 'reactstrap';
import Slider from './Components/Slider';


var checkboxes = {};
var parametro = 0;

class Test extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCuota: {},
      collapse: 1,
      collpases: {},
      items: {},
      nombre: Session.getInfoUserPagoRapido().Pusuario,
      rut: Session.getInfoUserPagoRapido().Prut,
      token_ws: 0,
      url: '',
      disabledPagar: true,
      show: true,
      cuotas: {
        0: {},
        1: {},
        2: {},
        3: {},
        4: {},
      },
      montoTotal: 0,
      bloqueo: [],
      checkDisabled: [],
      paymentType: 1,
      openmodal: false,
    };
    this.toggle = this.toggle.bind(this);
    this.onClick = this.onClick.bind(this);
    this.onClickPagar = this.onClickPagar.bind(this);
    this.getCuotas = this.getCuotas.bind(this);
    this.calculaMontoTotal = this.calculaMontoTotal.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.setCheckboxes = this.setCheckboxes.bind(this);
    this.checkOpcion = this.checkOpcion.bind(this);
    this.onClickPagarKhipu = this.onClickPagarKhipu.bind(this);
    this.onClickPopupLiberese = this.onClickPopupLiberese.bind(this);
    this.HasMPU = this.HasMPU.bind(this);

  }

  onClickPopupLiberese() {
    this.setState({
      openmodal: false,
    });
    
    let contrato = '';
    let aux_index = '';
    let items = this.state.items;

    items.map((item,index) => {
      if (item.isOpen) {
        contrato = item.contrato;
        aux_index = index;
      }
    });
    
    if (contrato !== '') {
      this.checkOpcion( { target: { checked: true } }, 2, contrato, 0);
      const radio = document.getElementById('opcion-' + contrato + '-2-'+aux_index);
      if (radio) {
        radio.checked = true;
        const targetElement = document.getElementById('select_option_mpu');
        if (targetElement) {
          targetElement.scrollIntoView({ behavior: 'smooth' });
        }
      }
    }

  }

  componentWillMount() {  
    this.getCuotas();
  }
  Logout() {
    localStorage.removeItem('Prut');
    localStorage.removeItem('Pcorreo');
    localStorage.removeItem('Pusuario');
    localStorage.removeItem('token');
    window.location.href = '/';
  }
  checkOpcion(e, opcion, contrato) {
    const { bloqueo } = this.state;
    bloqueo[contrato] = opcion;
    if (e.target.checked) {
      this.setState({
        bloqueo: bloqueo,
      });
      //console.log(bloqueo);
      //console.log(typeof bloqueo)
    } else {
      delete this.state.bloqueo[contrato];
    }
  }
  toggle(e, tab) {
    e.preventDefault();

    if (this.state.collapse !== tab) {
      this.setState({
        collapse: tab,
      });
    } else {
      this.setState({
        collapse: 0,
      });
    }
  }
  async getCuotas() {
    const dat = {};
    dat.rut = this.state.rut;
    var response = await Api.cuotasPagoRapido(dat);

    let defaultbloqueo;

    response.map((item) => {
      item.isOpen = true;

      defaultbloqueo = this.state.bloqueo;
      defaultbloqueo[item.contrato] = 1;

      this.setState({
        bloqueo: defaultbloqueo,
      });
    });

    this.setState({
      items: response,
      show: false,
    });

    if (this.HasMPU(this.state.items)) {
      this.setState({openmodal: true});
    }

  }
  
  HasMPU(items) {
    return items.some((item) => {
      return (
        typeof item.mantencion === 'object' &&
        typeof item.mpu === 'object' &&
        parseInt(item.mpu.vigente.monto) > 0 &&
        item.mpu.vigente.permite_mpu !== 'NO'
      );
    });
  }

  async onClickPagar() {
    const { rut, email, nombre, selectedCuota, montoTotal } = this.state;
    let cuotas = selectedCuota;
    if (email === '' || typeof email === 'undefined') {
      this.setState({
        errorEmail: 'error',
        type: 'vacio',
      });
      return false;
    }
    if (!validator.isEmail(email)) {
      this.setState({
        errorEmail: 'error',
        type: 'valido',
      });
      return false;
    }
    this.setState({
      show: true,
      disabledPagar: true,
    });
    console.info(cuotas);
    let response = await Api.PagarRapido({
      rut: rut,
      email: email,
      nombre: nombre,
      amount: montoTotal,
      cuotasSeleccionadas: JSON.stringify(cuotas),
    });
    this.setState({
      token_ws: response.token_ws,
      url: response.next_page,
    });
    document.getElementById('form_webpay').submit();
  }

  async onClickPagarKhipu() {
    const { rut, email, nombre, selectedCuota, montoTotal } = this.state;
    let cuotas = selectedCuota;
    if (email === '' || typeof email === 'undefined') {
      this.setState({
        errorEmail: 'error',
        type: 'vacio',
      });
      return false;
    }
    if (!validator.isEmail(email)) {
      this.setState({
        errorEmail: 'error',
        type: 'valido',
      });
      return false;
    }
    this.setState({
      show: true,
      disabledPagar: true,
    });

    let response = await Api.PagarRapidoKhipu({
      rut: rut,
      email: email,
      nombre: nombre,
      amount: montoTotal,
      cuotasSeleccionadas: JSON.stringify(cuotas),
    });

    this.setState({
      url: response.payment_url,
    });

    document.getElementById('form_webpay').submit();
  }
  onClick(e, items) {
    var objeto = {};
    const { bloqueo, checkDisabled } = this.state;
    if (e) {
      items.forEach((todo) => {
        if (todo.CONCEPTO_RECAUDACION === 'MPU') {
          bloqueo[todo.NUMERO_CONTRATO] = 2;
          checkDisabled[todo.NUMERO_CONTRATO] = true;
          this.setState({
            bloqueo: bloqueo,
            checkDisabled: checkDisabled,
          });
        } else if (todo.CONCEPTO_RECAUDACION === 'MNT') {
          bloqueo[todo.NUMERO_CONTRATO] = 1;
          checkDisabled[todo.NUMERO_CONTRATO] = true;
          this.setState({
            bloqueo: bloqueo,
            checkDisabled: checkDisabled,
          });
        }
        objeto = {
          NUMERO_CONTRATO: todo.NUMERO_CONTRATO,
          NUMERO_CUOTA: todo.NUMERO_CUOTA,
          CONCEPTO_RECAUDACION: todo.CONCEPTO_RECAUDACION,
          MONTO_CUOTA: parseInt(todo.MONTO_CUOTA),
          NUMERO_DOCUMENTO: todo.NUMERO_DOCUMENTO,
          CUOTA_MOROSA: todo.CUOTA_MOROSA,
          CODIGO_COMERCIO: todo.CODIGO_COMERCIO,
        };
        this.state.selectedCuota[
          todo.NUMERO_CONTRATO +
            '-' +
            todo.NUMERO_CUOTA +
            '-' +
            todo.CONCEPTO_RECAUDACION
        ] = objeto;
      });
    } else {
      var estado = 0;
      items.forEach((todo) => {
        delete this.state.selectedCuota[
          todo.NUMERO_CONTRATO +
            '-' +
            todo.NUMERO_CUOTA +
            '-' +
            todo.CONCEPTO_RECAUDACION
        ];
        if (todo.CONCEPTO_RECAUDACION === 'MPU') {
          bloqueo[todo.NUMERO_CONTRATO] = 2;
          checkDisabled[todo.NUMERO_CONTRATO] = false;
          this.setState({
            bloqueo: bloqueo,
            checkDisabled: checkDisabled,
          });
        }
        if (todo.CONCEPTO_RECAUDACION === 'MNT') {
          const { selectedCuota } = this.state;
          Object.keys(selectedCuota).map((item) => {
            if (item.includes('MNT')) {
              estado++;
            }
          });
          if (estado === 0) {
            bloqueo[todo.NUMERO_CONTRATO] = 1;
            checkDisabled[todo.NUMERO_CONTRATO] = false;
            this.setState({
              bloqueo: bloqueo,
              checkDisabled: checkDisabled,
            });
          }
        }
      });
    }
  }
  calculaMontoTotal() {
    var valor = 0;
    const { selectedCuota } = this.state;
    Object.keys(selectedCuota).map(
      (item) => (valor += parseInt(selectedCuota[item].MONTO_CUOTA))
    );
    this.setState({
      disabledPagar: valor > 0 ? false : true,
      montoTotal: valor,
    });
  }
  handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState({ [name]: value });
  }
  setCheckboxes(NUMERO_CONTRATO, NUMERO_CUOTA, type, estado) {
    var objeto = {};
    objeto = { estado };
    var pos = 0;
    if (type === 'F') {
      pos = 0;
    } else if (type === 'M') {
      pos = 1;
    } else if (type === 'CM') {
      pos = 2;
    } else {
      pos = 3;
      objeto.estado = false;
    }
    this.state.cuotas[pos][NUMERO_CONTRATO + '-' + type + '-' + NUMERO_CUOTA] =
      objeto;
    checkboxes = this.state.cuotas;
  }

  renderMensajeIVA() {
    const date = new Date();
    if (date.getFullYear() >= ViewIvas) {
      return (
        <>
          <div className="row">
            <div className="col-8">
              (*) Monto del{' '}
              <strong style={{ letterSpacing: '1.5px' }}>
                IVA A BENEFICIO FISCAL
              </strong>{' '}
              calculado sobre los precios de los servicios afectos a IVA. Para
              más información sobre estos servicios visite{' '}
              <a href="https://sendero.cl" target="_blank">
                www.sendero.cl
              </a>{' '}
              o comuníquese con nosotros al{' '}
              <span style={{ color: '#000', fontWeight: 'bold' }}>
                600 736 33 76
              </span>{' '}
              o en cualquiera de nuestras oficinas y cementerios parque.
            </div>
          </div>
        </>
      );
    }

    return <></>;
  }

  render() {
    const {
      items,
      nombre,
      email,
      disabledPagar,
      show,
      errorEmail,
      cuotas,
      montoTotal,
      type,
      bloqueo,
      checkDisabled,
    } = this.state;

    return (
      <>
        <Modal isOpen={this.state.openmodal} className="" style={{maxWidth: '95%'}}>
          <ModalBody>
            <Button
              color=""
              style={{
                border: '1px solid gray',
              }}
              onClick={(e) => this.setState({ openmodal: false })}
            >
              Cerrar
            </Button>
            <br />
            <div className='d-none d-lg-block'>
              <img
                  className="img img-fluid"
                  src="https://sendero-api.gux.cl//storage/banner_modal_desktop_liberese.png"
                  alt="Mensaje IVA"
                  height="300"
                  onClick={this.onClickPopupLiberese}
                  style={{ cursor: 'pointer' }}
                />
            </div>
            <div className='d-lg-none'>
              <br />  
              <img
                  className="img img-fluid"
                  src="https://sendero-api.gux.cl//storage/banner_modal_mobile_liberese.png"
                  alt="Mensaje IVA"
                  height="300"
                  onClick={this.onClickPopupLiberese}
                  style={{ cursor: 'pointer' }}
                />
            </div>  
          </ModalBody>
        </Modal>
        <form
          name="form_webpay"
          action={this.state.url}
          method="POST"
          id="form_webpay"
        >
          <input type="hidden" name="token_ws" value={this.state.token_ws} />
        </form>
        <div className="container">
          <div className="content-contratos text-center">
            <div className="row row-content-cabecera">
              <div className="col-12 col-md-4 center-mobile">
                <LogoSendero />
              </div>
              <div className="col-12 col-md-8 text-right center-mobile">
                <RS />
              </div>
            </div>
            <div className="row row-content-cabecera">
              <div className="col-12 col-md-6 text-left center-mobile">
                <h4>
                  Bienvenido <strong>{nombre}</strong>
                </h4>
              </div>
              <div className="col-12 col-md-6 text-right center-mobile">
                <p onClick={() => this.Logout()} style={{ cursor: 'pointer' }}>
                  <img src={casa} alt="" /> <strong>Volver a Sendero</strong>
                </p>
              </div>
            </div>
            {show ? (
              <Spinner style={{ width: '5rem', height: '5rem' }} />
            ) : items && items.length > 0 ? (
              <>
                {/* <p
                  style={{
                    textAlign: 'justify',
                    marginTop: '-25px',
                    color: '#fff',
                    padding: '10px',
                    backgroundColor: '#00A5C8',
                  }}
                >
                  Estimados Clientes, a partir del 01 de enero de 2023 comenzará
                  a regir la nueva Ley Nº 21.420, la cual indica que todos los
                  pagos de servicios se encontrarán afectos a IVA. Debido a esta
                  ley, Sendero está obligado legalmente a incorporar este
                  impuesto a las Mantenciones y otros servicios, los cuales
                  tendrán un recargo del 19% por concepto de IVA, según lo
                  exigido por la autoridad, lo que será recaudado y entregado al
                  Servicio de Impuestos Internos a beneficio fiscal.
                </p>
                <br /> */}
                {/* <img
                  src={bannerMPUX}
                  style={{
                    width: '100%',
                    marginBottom: '15px',
                    cursor: 'pointer',
                  }}
                  alt=""
                  onClick={(e) => {
                    window.location.href =
                      'https://sendero.cl/cotizar?utm_source=banner_site&utm_medium=banner_pago_rapido&utm_campaign=banner';
                  }}
                /> */}
                <Slider />
                {/* <img
                  src={bannerMPUXDESKTOP}
                  // style={{
                  //   width: '100%',
                  //   marginBottom: '15px',
                  //   cursor: 'pointer',
                  // }}
                  className='view_image_pago_rapido_desktop'
                  alt=""
                  onClick={(e) => {
                    window.location.href =
                      'https://sendero.cl/cotizar?utm_source=banner_site&utm_medium=banner_pago_rapido&utm_campaign=banner';
                  }}
                />
                <img
                  src={bannerMPUXMOBILE}
                  className='view_image_pago_rapido_mobile'
                  alt=""
                  onClick={(e) => {
                    window.location.href =
                      'https://sendero.cl/cotizar?utm_source=banner_site&utm_medium=banner_pago_rapido&utm_campaign=banner';
                  }}
                /> */}
                <div className="text-center text-introduccion">
                  <p>
                    Pagar su cuenta en Sendero desde ahora será mucho más fácil.
                    <br /> Aquí conocerá sus servicios contratados y cancelar en
                    pocos pasos su cuota de crédito y mantención.
                  </p>
                  <h4>Servicios Contratados:</h4>
                </div>
                {items.map((item, index) => (
                  <>
                    <div key={index}>
                      <div
                        onClick={(e) => {
                          let aux_items = this.state.items;
                          aux_items[index].isOpen =
                            !this.state.items[index].isOpen;
                          this.setState({
                            items: aux_items,
                          });
                        }}
                        className={
                          this.state.items[index].isOpen === true
                            ? 'open-collapse'
                            : 'text-list'
                        }
                      >
                        {/* <div onClick={(e) => { this.toggle(e, index + 1 ) }} className={(parseInt(this.state.collapse) === (index + 1) ? 'open-collapse' : '') + ' text-list'}> */}
                        <Cabecera {...item} />
                      </div>
                      {/* <Collapse isOpen={parseInt(this.state.collapse) === (index + 1) ? true : true}> */}
                      <Collapse isOpen={this.state.items[index].isOpen}>
                        <div className="">
                          <Adicional
                            parque={item.parque}
                            producto={item.producto}
                            contrato={item.contrato}
                            pie={
                              typeof item.pie !== 'undefined'
                                ? item.pie.vigente.adicional
                                : ''
                            }
                          />
                          <div className="content-contratos__productos">
                            {typeof item.financiamiento !== 'undefined' && (
                              <TableCuota
                                data={item.financiamiento}
                                title={item.financiamiento.titulo}
                                text={'Subtotal'}
                                id={'table-blue'}
                                type={'F'}
                                onClick={this.onClick}
                                calculaMontoTotal={this.calculaMontoTotal}
                                setCheckboxes={this.setCheckboxes}
                                checkboxes={cuotas}
                              />
                            )}
                            <div className="mnt-opcion">
                              {typeof item.mantencion === 'object' &&
                              typeof item.mpu === 'object' ? (
                                parseInt(item.mpu.vigente.monto) > 0 &&
                                item.mpu.vigente.permite_mpu !== 'NO' ? (
                                  <>
                                    <div className="row opciones">
                                      <div className="col-12">
                                        <h3 id="select_option_mpu">Seleccione la opción</h3>
                                      </div>
                                      <div className="col-6">
                                        <input
                                          name={'opcion' + item.contrato}
                                          id={'opcion-' + item.contrato + '-1-'+index}
                                          type="radio"
                                          onClick={(e) => {
                                            this.checkOpcion(
                                              e,
                                              1,
                                              item.contrato,
                                              index
                                            );
                                          }}
                                          disabled={
                                            checkDisabled[item.contrato]
                                          }
                                          defaultChecked
                                        />
                                        <h4>Mantención Anual</h4>
                                        <p>
                                          Corresponde al valor que cada año
                                          usted cancela, el cuál nos permite
                                          mantener cada mes los espacios comunes
                                          de nuestro Cementerio Parque. Esto
                                          considera el mejoramiento de caminos,
                                          paisajismo, riego y corte de césped.
                                        </p>
                                      </div>
                                      <div className="col-6">
                                        <input
                                          name={'opcion' + item.contrato}
                                          type="radio"
                                          id={'opcion-' + item.contrato + '-2-'+index}
                                          onClick={(e) => {
                                            console.log(item.contrato);
                                            this.checkOpcion(
                                              e,
                                              2,
                                              item.contrato,
                                              index
                                            );
                                          }}
                                          disabled={
                                            checkDisabled[item.contrato]
                                          }
                                        />
                                        <h4>Mantención Perpetua</h4>
                                        <p>
                                          Corresponde a un beneficio al que
                                          usted puede optar para liberarse del
                                          pago anual de la mantención de nuestro
                                          Cementerio Parque de manera
                                          definitiva.
                                        </p>
                                      </div>
                                    </div>
                                    <div
                                      className={
                                        'pantalla' +
                                        (parseInt(bloqueo[item.contrato]) !== 1
                                          ? ' verifica'
                                          : '')
                                      }
                                    >
                                      <div className={'bloque-pago inactivo'}>
                                        {' '}
                                      </div>
                                      <TableCuota
                                        data={item.mantencion}
                                        title={item.mantencion.titulo}
                                        text={'Subtotal'}
                                        id={'table-green'}
                                        type={'M'}
                                        onClick={this.onClick}
                                        calculaMontoTotal={
                                          this.calculaMontoTotal
                                        }
                                        setCheckboxes={this.setCheckboxes}
                                        checkboxes={cuotas}
                                      />
                                    </div>
                                    <div
                                      className={
                                        'pantalla' +
                                        (parseInt(bloqueo[item.contrato]) !== 2
                                          ? ' verifica'
                                          : '')
                                      }
                                    >
                                      <div className={'bloque-pago inactivo'}>
                                        {' '}
                                      </div>
                                      <TableCuota
                                        data={item.mpu}
                                        title={item.mpu.titulo}
                                        text={'Subtotal'}
                                        id={'table-blue'}
                                        type={'MP'}
                                        onClick={this.onClick}
                                        calculaMontoTotal={
                                          this.calculaMontoTotal
                                        }
                                        setCheckboxes={this.setCheckboxes}
                                        checkboxes={cuotas}
                                      />
                                    </div>
                                  </>
                                ) : (
                                  <TableCuota
                                    data={item.mantencion}
                                    title={item.mantencion.titulo}
                                    text={'Subtotal'}
                                    id={'table-green'}
                                    type={'M'}
                                    onClick={this.onClick}
                                    calculaMontoTotal={this.calculaMontoTotal}
                                    setCheckboxes={this.setCheckboxes}
                                    checkboxes={cuotas}
                                    parametro={parametro}
                                  />
                                )
                              ) : (
                                typeof item.mantencion !== 'undefined' && (
                                  <TableCuota
                                    data={item.mantencion}
                                    title={item.mantencion.titulo}
                                    text={'Subtotal'}
                                    id={'table-green'}
                                    type={'M'}
                                    onClick={this.onClick}
                                    calculaMontoTotal={this.calculaMontoTotal}
                                    setCheckboxes={this.setCheckboxes}
                                    checkboxes={cuotas}
                                    parametro={parametro}
                                  />
                                )
                              )}
                            </div>
                            {typeof item.cuponera !== 'undefined' && (
                              <TableCuota
                                data={item.cuponera}
                                title={item.cuponera.titulo}
                                text={'Subtotal'}
                                id={'table-gray'}
                                type={'CM'}
                                onClick={this.onClick}
                                calculaMontoTotal={this.calculaMontoTotal}
                                setCheckboxes={this.setCheckboxes}
                                checkboxes={cuotas}
                              />
                            )}
                            {typeof item.vitalicia !== 'undefined' &&
                              item.vitalicia.vigente.permite_vit !== 'NO' && (
                                <TableCuota
                                  data={item.vitalicia}
                                  title={item.vitalicia.titulo}
                                  text={'Subtotal'}
                                  id={'table-blue'}
                                  type={'VT'}
                                  onClick={this.onClick}
                                  calculaMontoTotal={this.calculaMontoTotal}
                                  setCheckboxes={this.setCheckboxes}
                                  checkboxes={cuotas}
                                />
                              )}
                            {typeof item.servicios !== 'undefined' &&
                              item.servicios.vigente.permite_srv !== 'NO' && (
                                <TableCuota
                                  data={item.servicios}
                                  title={item.servicios.titulo}
                                  text={'Subtotal'}
                                  id={'table-blue'}
                                  type={'SS'}
                                  onClick={this.onClick}
                                  calculaMontoTotal={this.calculaMontoTotal}
                                  setCheckboxes={this.setCheckboxes}
                                  checkboxes={cuotas}
                                />
                              )}
                            {typeof item.pie !== 'undefined' && (
                              <TablePie
                                data={item.pie}
                                title={item.pie.titulo}
                                text={'Subtotal'}
                                id={'table-gray'}
                                type={'P'}
                                onClick={this.onClick}
                                calculaMontoTotal={this.calculaMontoTotal}
                              />
                            )}
                          </div>
                        </div>
                      </Collapse>
                    </div>
                  </>
                ))}
                <div>
                  {this.renderMensajeIVA()}
                  <div className="total">
                    <div className="row">
                      <div className="col-6 text-center">Total a pagar</div>
                      <div className="col-6 text-center">
                        ${new Intl.NumberFormat('de-DE').format(montoTotal)}
                      </div>
                    </div>
                  </div>
                  <div className="checkout">
                    <div className="row">
                      <div className="col-12 col-md-6 offset-md-6 text-left">
                        <p>Ingrese email para recibir comprobante de pago</p>
                        <input
                          type="text"
                          name="email"
                          id="email"
                          className={'form-control ' + errorEmail}
                          onChange={this.handleInputChange}
                          value={email || ''}
                        />
                      </div>
                      {errorEmail === 'error' && (
                        <>
                          <div className="col-12 col-md-6 offset-md-6 text-left">
                            <br />
                            <div className="alert alert-danger">
                              {type === 'vacio'
                                ? 'Debe ingresar e-mail'
                                : 'Debe ingresar e-mail válido'}
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                    <div className="sl-method-pay row mt-3 text-center">
                      <label className="col-12">
                        <h4>Seleccione el método de pago</h4>
                      </label>
                      <div className="col-12  d-flex justify-content-around align-items-center">
                        <label className="radio2">
                          <input
                            type="radio"
                            className="mnt-opcion"
                            name="paymentType"
                            defaultChecked
                            defaultValue="1"
                            value={1}
                            style={{ zoom: '2' }}
                            onChange={(e) => this.handleInputChange(e)}
                          />
                          <p>Tarjeta Crédito / Débito</p>
                          <img alt="" src={webpayTransbank} />
                          <span className="check2"></span>
                        </label>
                        <label className="radio2">
                          <input
                            type="radio"
                            name="paymentType"
                            value={2}
                            style={{ zoom: '2' }}
                            onChange={(e) => this.handleInputChange(e)}
                          />
                          <p>
                            Transferencia desde su banco <PdfPopUp />
                          </p>
                          <img alt="" src={khipu} />
                          <span className="check2"></span>
                        </label>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12  text-center">
                        <Button
                          className="btn btn-verde "
                          onClick={
                            this.state.paymentType === 1
                              ? this.onClickPagar
                              : this.onClickPagarKhipu
                          }
                          disabled={disabledPagar}
                        >
                          {show ? (
                            <Spinner
                              style={{ width: '2rem', height: '2rem' }}
                            />
                          ) : (
                            <>PAGAR</>
                          )}
                        </Button>
                      </div>
                    </div>
                  </div>
                  <Footer />
                </div>
              </>
            ) : (
              <h1>NO TIENE CONTRATOS ASOCIADOS</h1>
            )}
          </div>
        </div>
      </>
    );
  }
}
export default Test;
