import React, { Component } from 'react';

class Monto extends Component {
	constructor(props) {
		super(props)
		this.state = {
			valor: this.props.valor,
		}
		this.onChange = this.onChange.bind(this);
	}
	onChange(event) {
		const { value } = event.target;
		this.setState({
			valor: value
		});
	}
	render() {
		const { correlaivo, contrato, InsertarValor } = this.props;
		const { valor } = this.state;
		return (
			<>
				{valor > 0 && (
					<input
						type="text"
						className="form-control"
						onBlur={(e) => { InsertarValor(e, correlaivo, contrato) }}
						value={valor}
						onChange={this.onChange}
					/>
				)}
			</>
		)
	}
}
export default Monto;