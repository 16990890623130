import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Desktop from './routesDesktop';
import Header from './components/Header/';
import Footer from './components/Footer/';
import MiSendero from './mi-sendero/routes';
import PagoRapido from './pago-rapido/routes';
import Forme from './pages/Form';
import Login from './mi-sendero/Pages/Login';

const renderContent = () => {
    return (
        <Router>
            <Switch>
                <Route path="/mi-sendero" component={MiSendero} />
                <Route path="/acepta-contrato" component={() => (<><Login iscontrato={1} /></>)} />
                <Route path="/pago-rapido" component={PagoRapido} />
                <Route path="/contact-center" component={Forme} />
                <Route component={() => (<><Header /><Desktop /><Footer /></>)} />
            </Switch>
        </Router>
    );
}


export default renderContent;